import {  Block, FlexBox, FormBase,  Text, globalModalState, useHttpCommand, useHttpQuery } from '@esg/ui';
import { getBookingOnlineSetting, getAllBranches } from '@esg/business-link-booking';
import { AppointmentCreateRequest, AppointmentCreateResponse, createBooking } from '@esg/business-link-booking/api/createBooking';
import { useCallback } from 'react';
import { Spin} from 'antd';
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import { getCurrentPageBookingSetting } from '@esg/business-link-booking/api';
import HeaderPage from './children/HeaderPage';
import BookingLocalesProvider from './children/BookingLocalesProvider';
import ModalBookingSuccess from './children/ModalBookingSuccess';
import BookingContent from './children/BookingContent';
import LayoutContainer from '@/components/layouts/LayoutContainer';

import './Home.scss';
import Copyright from '@/components/Copyright/Copyright';

interface HomeProps {
    domain?: string;
}

export default function Home(props: HomeProps) {
    const [, setGlobalModal] = useRecoilState(globalModalState);
    const { slug } = useParams();
    const slugToQuery = props.domain ?? slug;

    const {data: branches, isLoading: branchLoading} = useHttpQuery(getAllBranches, {
        pathData: { slug: slugToQuery }
    });

    const {data: bookingOnlineSettings, isLoading: settingLoading} = useHttpQuery(getBookingOnlineSetting, {
        pathData: { slug: slugToQuery }
    });

    const {data: pageBookingSetting } = useHttpQuery(getCurrentPageBookingSetting, {
        pathData: { slug: slugToQuery }
    });

    const defaultValues: AppointmentCreateRequest['body'] = {
        numberOfGuest: 1,
        notes: '',
        appointmentDate: undefined,
        branchId: branches?.[0]?.id,
        appointmentServices: [
            {
                appointmentServiceDetails: []
            }
        ],
    };

    const showModalBookingSuccess = useCallback((appointment: AppointmentCreateResponse) => {
        const branch = branches?.find((b) => b.id === appointment.branchId);

        setGlobalModal({
            isOpen: true,
            showModalConfirm: true,
            footer: null,
            onCancel: ()=>{
                window.location.reload();
            },
            content: <ModalBookingSuccess
                branch={branch}
                appointment={appointment}
                pageBookingSetting={pageBookingSetting}
            />
        });
    },[branches, setGlobalModal, pageBookingSetting]);

    const {mutateAsync, isPending} = useHttpCommand(createBooking, {
        onSuccess: (data) => showModalBookingSuccess(data)
    });

    const beforeSubmit = useCallback((values: AppointmentCreateRequest['body']) => {
        return {
            ...values,
            appointmentServices: values.appointmentServices.map((a) => ({
                ...a,
                appointmentServiceDetails: a.appointmentServiceDetails.map((s) => ({
                    ...s,
                    appointmentServiceEmployees: s.appointmentServiceEmployees?.map((st) => ({
                        ...st,
                        timeStartWork: s.startTime,
                        timeEndWork: s.endTime,
                    })),
                })),
            })),
        };
    }, []);

    const onSubmit = useCallback( async (data: AppointmentCreateRequest['body']) => {
        const bodyValue = beforeSubmit(data);
        await mutateAsync({
            body:bodyValue,
            pathData: { slug: slugToQuery }
        });
    }, [beforeSubmit, mutateAsync, slugToQuery]);

    if(branchLoading || settingLoading) return <Spin fullscreen spinning />;

    if(!pageBookingSetting?.allowBookingOnline) {
        return (
            <Block>
                <LayoutContainer>
                    <FlexBox justifyContent='center' alignItems='center' height='100vh'>
                        <Text fontSize={20} fontWeight={700}>Booking online is not available</Text>
                    </FlexBox>
                </LayoutContainer>
            </Block>
        );
    }

    return (
        <Block className='home-page'>
            <Spin spinning={isPending} fullscreen/>
            <HeaderPage pageBookingSetting={pageBookingSetting} isDescription={false}/>
            <LayoutContainer>
                <FormBase onSubmit={onSubmit} defaultValues={defaultValues} className='form-wrapper'>
                    <BookingLocalesProvider branches={branches}>
                        <BookingContent
                            branches={branches}
                            bookingOnlineSettings={bookingOnlineSettings}
                            slugToQuery={slugToQuery}
                            pageBookingSetting={pageBookingSetting}
                        />
                    </BookingLocalesProvider>
                </FormBase>
                <Copyright/>
            </LayoutContainer>
        </Block>
    );
}